import React from "react";
import "./EventDetailsCard.css";

const EventDetailsCard = ({ Details }) => {
  const date = Details.event_date.split("/");
  const updateDate = `${date[2]}-${date[0]}-${date[1]}`;
  const utcDate = new Date(`${updateDate}T${Details.event_time}Z`);
  const localDateTime = {
    date: `${utcDate.toLocaleDateString()}`,
    time: `${utcDate.toLocaleTimeString()}`,
  };

  function timeConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  const priceForAllBets =
    Details.price_per_bet && Details.price_per_bet * Details.no_of_questions;

  return (
    <>
      <div className="card mt-sm-4 mb-sm-4">
        <div className="row m-0">
          <div className="col-lg-3 p-0 d-flex justify-center align-items-center">
            <div className="card-body text-center mt-4 mb-4">
              <img
                src={Details.image_url}
                alt="avatar"
                className=" img-fluid"
                style={{
                  width: "250px",
                  height: "250px",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>

          <div className="col-lg-9 mt-4 mb-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 event-detail">
                        <div>
                          <label>Event Title</label>
                          <p className="text-muted mb-0">
                            {Details.event_title}
                          </p>
                        </div>
                        <div>
                          <label>Description</label>
                          <p className="text-muted mb-0">
                            {" "}
                            {Details.event_description}
                          </p>
                        </div>
                        <div>
                          <label>Address</label>
                          <p className="text-muted mb-0">
                            {" "}
                            {Details.event_location}
                          </p>
                        </div>
                        <div>
                          <label>Event Organizer Name</label>
                          <p className="text-muted mb-0">
                            {" "}
                            {Details.event_creators_full_name
                              ? Details.event_creators_full_name
                              : "NA"}
                          </p>
                        </div>
                        <div>
                          <label>Event Organizer Email</label>
                          <p className="text-muted mb-0">
                            {" "}
                            {Details.event_creators_email
                              ? Details.event_creators_email
                              : "NA"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 event-detail">
                        <div>
                          <label>Groom/Bride Email</label>
                          <p className="text-muted mb-0">
                            {" "}
                            {Details.groom_or_bride_email
                              ? Details.groom_or_bride_email
                              : "NA"}
                          </p>
                        </div>
                        <div>
                          <label>Event Date</label>
                          <p className="text-muted mb-0">
                            {" "}
                            {localDateTime.date}
                          </p>
                        </div>
                        <div>
                          <label>Event Time</label>
                          <p className="text-muted mb-0">
                            {" "}
                            {timeConvert(localDateTime.time)}
                          </p>
                        </div>
                        <div className="w-75">
                          <label>Invite link</label>
                          <p className="text-muted mb-0">
                            <a
                              href={Details.invite_link}
                              className="text-muted mb-0"
                            >
                              {" "}
                              {Details.invite_link}
                            </a>
                          </p>
                        </div>
                        <div>
                          <label>Created date</label>
                          <p className="text-muted mb-0">
                            {new Date(Details.created_at).toLocaleString()}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 event-detail">
                        <div>
                          <label>Is Bride/Groom</label>
                          <p className="text-muted mb-0">
                            {Details.is_groom_or_bride === null
                              ? "NA"
                              : Details.is_groom_or_bride
                              ? "Yes"
                              : "No"}
                          </p>
                        </div>
                        <div>
                          <label>Number Of Questions</label>
                          <p className="text-muted mb-0">
                            {" "}
                            {Details.no_of_questions}
                          </p>
                        </div>
                        <div>
                          <label>Amount for per Bets</label>
                          <p className="text-muted mb-0">
                            {Details.price_per_bet
                              ? `${Details.price_per_bet} ${Details.currency_type}`
                              : "NA"}
                            {/* {Details.price_per_bet} {Details.currency_type} */}
                          </p>
                        </div>
                        <div>
                          <label>Amount for all Bets</label>
                          <p className="text-muted mb-0">
                            {Details.price_per_bet
                              ? `${priceForAllBets} ${Details.currency_type}`
                              : "NA"}
                            {/* {Details.price_per_bet} {Details.currency_type} */}
                          </p>
                        </div>
                        <div>
                          <label>Number of Participants</label>
                          <p className="text-muted mb-0">
                            {" "}
                            {Details.no_of_participants}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetailsCard;
